import { Settings } from 'src/@core/context/settingsContext'
import { useAuth } from 'src/@core/hooks/useAuth'
import { NavLink, NavSectionTitle, VerticalNavItemsType } from 'src/@core/layouts/types'
import Box from '@mui/material/Box'
import VerticalNavLink from './VerticalNavLink'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface Props {
  settings: Settings
  navVisible?: boolean
  groupActive: string[]
  currentActiveGroup: string[]
  verticalNavItems?: VerticalNavItemsType
  saveSettings: (values: Settings) => void
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
}

const resolveNavItemComponent = (item: NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle

  return VerticalNavLink
}

const VerticalNavItems = (props: Props) => {
  const { verticalNavItems } = props
  const { isAdmin, logout } = useAuth()
  const history = useHistory()

  const { t } = useTranslation()

  const RenderMenuItems = verticalNavItems?.map(
    (item: NavLink | NavSectionTitle, index: number) => {
      const TagName: any = resolveNavItemComponent(item)

      switch (item.title) {
        // @ts-ignore
        case `${t('layout.payments')}`: {
          if (!isAdmin) break
        }
        // @ts-ignore
        case `${t('layout.bookings')}`: {
          if (!isAdmin) break
        }
        // @ts-ignore
        case `${t('layout.subscriptions')}`: {
          if (!isAdmin) break
        }
        // @ts-ignore
        case `${t('layout.export-users-review')}`: {
          if (!isAdmin) break
        }
        default: {
          return <TagName {...props} key={index} item={item} />
        }
      }
    }
  )

  return (
    <>
      {RenderMenuItems}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: 300,
          margin: 6,
          pl: 10,
          pb: 6,
        }}
      >
        <Link
          underline='none'
          href='/login'
          sx={{ color: 'customColors.mainGreen' }}
          onClick={() => {
            logout()
          }}
        >
          {t('layout.log-out')}
        </Link>
      </Box>
    </>
  )
}

export default VerticalNavItems
