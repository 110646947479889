// ** MUI Components
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import Link from '@mui/material/Link'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

// localization
import { useTranslation } from 'react-i18next'

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw',
  },
}))

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 450,
    marginTop: theme.spacing(10),
  },
  [theme.breakpoints.down('md')]: {
    height: 400,
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(13),
  },
}))

const TreeIllustration = styled('img')(({ theme }) => ({
  left: 0,
  bottom: '5rem',
  position: 'absolute',
  [theme.breakpoints.down('lg')]: {
    bottom: 0,
  },
}))

const Error404 = () => {
  const { t } = useTranslation()

  return (
    <BlankLayout>
      <Box className='content-center'>
        <Box
          sx={{
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <BoxWrapper>
            <Typography variant='h1'>404</Typography>
            <Typography variant='h5' sx={{ mb: 4 }}>
              {t('404.title')} ⚠️
            </Typography>
            <Typography variant='body1' sx={{ mb: 4 }}>
              {t('404.text')}
            </Typography>
          </BoxWrapper>
          <Link href='/'>
            <Button component='a' variant='contained' sx={{ px: 5.5, color: 'text.additional' }}>
              {t('404.home-button')}
            </Button>
          </Link>
        </Box>
      </Box>
    </BlankLayout>
  )
}

export default Error404
