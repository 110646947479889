import { VerticalNavItemsType } from 'src/@core/layouts/types'

import { useTranslation } from 'react-i18next'

const Navigation = (): VerticalNavItemsType => {
  const { t } = useTranslation()

  return [
    {
      title: `${t('layout.meetings')}`,
      path: '/meetings',
    },
    {
      title: `${t('layout.patients')}`,
      path: '/patients',
    },
    {
      title: `${t('layout.payments')}`,
      path: '/payments',
    },
    {
      title: `${t('layout.bookings')}`,
      path: '/bookings',
    },
    {
      title: `${t('layout.subscriptions')}`,
      path: '/subscriptions',
    },
    {
      title: `${t('layout.export-users-review')}`,
      path: '/export-users-review',
    },
  ]
}

export default Navigation
