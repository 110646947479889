import i18next from 'i18next'
import { validate as validateUuid, version as getUuidVersion } from 'uuid'
import { BookingStatus, PaymentStatus, PaymentType } from '../types.ts'

// funciton that return YYYY-MM-DD from date object
export const getDate = (date: Date, format = 'YYYY-MM-DD'): string => {
  const year = date.getFullYear() + ''
  const month = (date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + ''
  const day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
  format.split('-').forEach((item) => {
    if (item === 'YYYY') {
      format = format.replace(item, year)
    } else if (item === 'MM') {
      format = format.replace(item, month)
    } else if (item === 'DD') {
      format = format.replace(item, day)
    }
  })
  return format
}

export const validateSwedishPersonalNumber = (personalNumber: string) =>
  personalNumber.match(/^(19|20)(\d{6}-\d{4})$/)

export const validateSwedishPersonalNumberWithoutDash = (personalNumber: string) =>
  personalNumber.match(/^(19|20)(\d{10})$/)

export const validateEmail = (email: string) =>
  email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)

export const validateUserId = (userId: string) =>
  validateUuid(userId) && getUuidVersion(userId) === 4

export const getPaymentStatusTranslated = (paymentStatus: string) =>
  ({
    [PaymentStatus.PaidByPatient]: i18next.t('payments.status.paid'),
    [PaymentStatus.Refunded]: i18next.t('payments.status.refunded'),
  }[paymentStatus])

export const getBookingStatusTranslated = (bookingStatus: string) =>
  ({
    [BookingStatus.Future]: i18next.t('bookings.status.future'),
    [BookingStatus.Fulfilled]: i18next.t('bookings.status.fulfilled'),
    [BookingStatus.Cancelled]: i18next.t('bookings.status.cancelled'),
  }[bookingStatus])

export const getPaymentStatus = (paymentType: number) => {
  switch (paymentType) {
    case PaymentType.PaidByPatient:
      return PaymentStatus.PaidByPatient
    case PaymentType.Refunded:
      return PaymentStatus.Refunded
    case PaymentType.NoStatus:
      return null
  }
}

export const getBookingStatus = (paymentType: number, date: string) => {
  return paymentType === PaymentType.Refunded
    ? BookingStatus.Cancelled
    : new Date(Date.parse(date)) > new Date()
    ? BookingStatus.Future
    : BookingStatus.Fulfilled
}
