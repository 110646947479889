import {ElementType} from 'react'
import ListItem from '@mui/material/ListItem'
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, {BoxProps} from '@mui/material/Box'
import ListItemButton, {ListItemButtonProps} from '@mui/material/ListItemButton'
import themeConfig from 'src/lib/configs/themeConfig'
import {NavLink} from 'src/@core/layouts/types'
import {Settings} from 'src/@core/context/settingsContext'
import {Link, useLocation} from 'react-router-dom'

interface Props {
  item: NavLink
  settings: Settings
  navVisible?: boolean
  toggleNavVisibility: () => void
}

const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & { component?: ElementType; target?: '_blank' | undefined }
>(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4.25, 3.5),
  borderLeft: '4px solid transparent',
  '&.active, &.active:hover': {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  },
  '&.active .MuiTypography-root, &.active .MuiSvgIcon-root': {},
}))

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' }),
})

const VerticalNavLink = ({ item, navVisible, toggleNavVisibility }: Props) => {
  const { pathname } = useLocation() as any

  const isNavLinkActive = () => {
    if (pathname === item.path ||
      (item.path?.includes(pathname.split('/')[1]) && item.path !== '/')) {
      return true
    } else {
      return false
    }
  }

  return (
    <ListItem
      disablePadding
      className="nav-link"
      disabled={item.disabled || false}
      sx={{ mt: 1.5, px: '0 !important' }}
    >
      <Link to={item.path === undefined ? '/' : `${item.path}`} style={{ textDecoration: 'none', width: '100%'}}>
        <MenuNavLink
          component={'div'}
          className={isNavLinkActive() ? 'active' : ''}
          {...(item.openInNewTab ? { target: '_blank' } : null)}
          onClick={(e) => {
            if (item.path === undefined) {
              e.preventDefault()
              e.stopPropagation()
            }
            if (navVisible) {
              toggleNavVisibility()
            }
          }}
          sx={{
            pl: 15,
            ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
          }}
        >
          <MenuItemTextMetaWrapper>
            <Typography sx={{ color: 'customColors.mainGreen' }} {...(themeConfig.menuTextTruncate && { noWrap: true })}>
              {item.title}
            </Typography>
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </Link>
    </ListItem>
  )
}

export default VerticalNavLink
