export enum PaymentType {
  PaidByPatient = 1,
  Refunded = 9,
  NoStatus = 10,
}

export enum PaymentStatus {
  PaidByPatient = 'Paid',
  Refunded = 'Refunded',
}

export enum BookingStatus {
  Future = 'Future',
  Fulfilled = 'Fulfilled',
  Cancelled = 'Cancelled',
}

export interface Summary {
  averageCycle: SummaryAverageCycle | null
  periods: SummaryPeriod[]
  entries: SummaryEntry[]
  cycles: object[]
}

export interface SummaryByDateObj {
  [date: string]: Summary
}

export interface SummaryAverageCycle {
  duration: string
  length: string
  variation: string
}

export interface SummaryPeriod {
  start: string
  end: string
}

export interface SummaryEntry {
  date: string
  period: boolean
  bioIdenticalProgesterone: boolean
  isSpotting?: boolean
  nonBioIdenticalProgesterone: boolean
  estrogen: boolean
  hormonalContraceptive: boolean
  symptoms: {
    id: string
    level: string
  }[]
  note?: string | null
}
