import { createContext, useState, ReactNode, useEffect } from 'react'
import * as http from 'src/lib/services/http'
import jwt_decode from 'jwt-decode'
import { ResponseError, UnauthorizedError } from 'src/lib/services/errors'

interface AccessToken {
  idToken: string
  refreshToken: string
  expiresIn: string
}

export type AuthContextValue = {
  isAdmin: boolean
  user: any
  authed: boolean
  authenticateWithCode: (code: string | null) => Promise<boolean>
  logout: () => void
  loginBySavedTokens: () => boolean
  hercareRequest: (url: string, method?: 'POST' | 'GET', body?: any, options?: any) => Promise<any>
}

export const AuthContext = createContext<AuthContextValue>({
  isAdmin: false,
  user: null,
  authed: false,
  authenticateWithCode: (code: string | null) => Promise.resolve(false),
  logout: () => null,
  loginBySavedTokens: () => false,
  hercareRequest: () => Promise.resolve(null),
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  return <AuthContext.Provider value={authProviderHandler()}>{children}</AuthContext.Provider>
}

const authProviderHandler = () => {
  const [state, setState] = useState({
    isAdmin: false,
    user: null,
    authed: false,
  })

  const loginBySavedTokens = () => {
    const { accessToken, refreshToken } = getTokens()

    if (!accessToken || !refreshToken) {
      removeUser()
      return false
    }

    try {
      const decodedToken: any = decodeToken(accessToken)
      setState({
        isAdmin: decodedToken.isAdmin,
        user: decodedToken.name,
        authed: true,
      })
      return true
    } catch (err) {
      return false
    }
  }

  const getAccessTokenByCode = async (code: string) => {
    const data = await http.fetchApi('/auth/token/caregiver', 'POST', {
      code,
      state: { postCallbackRedirectUrl: process.env.REACT_APP_CRIIPTO_REDIRECT_URI },
    })
    return data
  }

  const authenticateWithCode = async (code: string | null) => {
    if (!code) {
      // Uncomment the code if you want to logout the user who is trying to login with empty code
      // removeUser()
      return false
    }
    let accessToken: any
    try {
      accessToken = await getAccessTokenByCode(code)
    } catch (e: any) {
      removeUser()
      return false
    }

    if (accessToken) {
      const decodedToken: any = decodeToken(accessToken.idToken)

      storeTokens(accessToken.idToken, accessToken.refreshToken)
      setState({
        isAdmin: decodedToken.isAdmin,
        user: decodedToken.name,
        authed: true,
      })
      return true
    }
    return false
  }

  const hercareRequest = async (
    url: string,
    method: 'POST' | 'GET' = 'GET',
    body?: any,
    options?: any
  ): Promise<any> => {
    if (!state.authed) {
      return null
    }
    const { accessToken } = getTokens()
    try {
      const result = await http.fetchWithAuth(accessToken, url, method, body)
      return result
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        try {
          try {
            await refreshTokens()
          } catch {
            throw new UnauthorizedError('Error in Refresh Token Function')
          }
          const { accessToken } = getTokens()
          const result = await http.fetchWithAuth(accessToken, url, method, body)
          return result
        } catch (errorSecondTry) {
          if (errorSecondTry instanceof UnauthorizedError) {
            removeUser() // redirect to login
          } else {
            throw error
          }
        }
      } else {
        throw error
      }
    }
  }

  const refreshTokens = async (): Promise<boolean> => {
    const { accessToken, refreshToken } = getTokens()
    if (!refreshToken) {
      removeUser()
      return false
    }

    const newTokenRequest = await http.fetchApi('/auth/token/caregiver/refresh', 'POST', {
      refreshToken,
    })

    const newAccessToken = newTokenRequest as AccessToken
    storeTokens(newAccessToken.idToken, newAccessToken.refreshToken)

    return true
  }

  const removeUser = () => {
    removeTokens() // tokens are invalid => remove them
    setState({
      isAdmin: false,
      user: null,
      authed: false,
    }) // automatically redirect to login since we have protected route
  }

  const storeTokens = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
  }

  const removeTokens = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }

  const getTokens = () => {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    return { accessToken, refreshToken }
  }

  const decodeToken = (token: string) => {
    const decodedToken = jwt_decode(token)
    return decodedToken
  }

  const logout = () => {
    removeUser()
  }

  return {
    authenticateWithCode,
    hercareRequest,
    logout,
    loginBySavedTokens,
    ...state,
  }
}
