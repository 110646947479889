export class ResponseError extends Error {
  constructor(message: string) {
    const errorMessage = `Something went wrong. Please try again later. ${message}`
    super(errorMessage)
    this.name = this.constructor.name
    this.message = errorMessage
  }
}

export class UnauthorizedError extends Error {
  constructor(message: string) {
    const errorMessage = `Unauthorized Error. Please login again. ${message}`
    super(errorMessage)
    this.name = this.constructor.name
    this.message = errorMessage
  }
}

export const handleError = async (res: Response) => {
  if (!res.ok) {
    const isJson = res.headers.get('content-type')?.includes('application/json')
    const data = isJson ? await res.json() : null
    const error = (data && data.message) || `Status: ${res.status}`
    if (res.status === 401) {
      throw new UnauthorizedError(error)
    } else {
      throw new ResponseError(error)
    }
  }
  return res.json()
}
