import NProgress from 'nprogress'
import { FC, useEffect } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { useAuth } from 'src/@core/hooks/useAuth'
import themeConfig from 'src/lib/configs/themeConfig'

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.authed) {
    return <Route {...rest} />
  } else {
    return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
  }
}

export const RouteWithLoader: FC<React.ComponentProps<typeof Route>> = (props) => {
  const location = useLocation()

  useEffect(() => {
    if (themeConfig.routingLoader) {
      NProgress.start()
      NProgress.done()
    }
  }, [location.pathname])

  return <Route {...props} />
}
