import { Suspense, useContext } from 'react'

// ** Routing Imports
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { RouteWithLoader } from './components/Routes/Routes'

// ** Global css styles
import 'src/styles/globals.css'

// ** Global fonts
import 'src/styles/fonts.css'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Component Imports
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import UserLayout from 'src/lib/layouts/UserLayout'
import Login from './pages/login'
import Error404 from './pages/404'

// ** Routes Import
import { routesArr } from 'src/lib/routes'

// ** Contexts
import { SettingsProvider, SettingsContext } from 'src/@core/context/settingsContext'
import { AuthProvider } from 'src/@core/context/authContext'

const App = () => {
  const settingsCtx = useContext(SettingsContext)

  return (
    <BrowserRouter>
      <AuthProvider>
        <SettingsProvider>
          <ThemeComponent settings={settingsCtx.settings}>
            <Suspense fallback={<span>Loading...</span>}>
              <Switch>
                {/* Redirect to Meetings */}
                <RouteWithLoader exact path='/'>
                  <Redirect to='/meetings' />
                </RouteWithLoader>

                <RouteWithLoader exact path='/login' component={Login} />

                <RouteWithLoader path={[...routesArr.map((r) => r.path)]} component={UserLayout} />

                {/* Error404 Page */}
                <RouteWithLoader path='*' component={Error404} />
              </Switch>
            </Suspense>
          </ThemeComponent>
        </SettingsProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
