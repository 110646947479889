import { handleError } from '../errors'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const fetchWithAuth = (
  bearer: string | null,
  url: string,
  method: 'POST' | 'GET',
  body?: any
) =>
  fetch(BASE_URL + url, {
    method: method,
    headers: {
      Authorization: `Bearer ${bearer}`,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: JSON.stringify(body),
  })
    .then(handleError)
    .catch((err) => {
      console.log(err)
      throw err
    })

export const fetchApi = (url: string, method: 'POST' | 'GET' = 'GET', body?: any) =>
  fetch(BASE_URL + url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: JSON.stringify(body),
  })
    .then(handleError)
    .catch((err) => {
      console.log(err)
      throw err
    })
