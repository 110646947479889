import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { routesArr, RouteType } from '../lib/routes'
import { PrivateRoute } from './Routes/Routes'

const AppContent = () => {
  return (
    <Suspense fallback={''}>
      <Switch>
        {routesArr.map((route: RouteType, idx: number) => {
          return (
            route.component && (
              <PrivateRoute
                key={idx}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <>
                    <route.component {...props} />
                  </>
                )}
              />
            )
          )
        })}
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppContent)
