import React, { MouseEvent, useEffect, useState } from 'react'
import { IMaskInput } from 'react-imask'
import { validateSwedishPersonalNumber } from 'src/lib/utils'

// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import MuiCard, { CardProps } from '@mui/material/Card'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

import { useAuth } from 'src/@core/hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '35rem' },
}))

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask='########-####'
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref as any}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const Login = (props: any) => {
  const [personalNumber, setPersonalNumber] = useState<string>()
  const [otherDeviceButtonDisabled, setOtherDeviceButtonDisabled] = useState<boolean>(true)
  const history = useHistory()
  const auth = useAuth()
  const [authLoading, setAuthLoading] = useState<boolean>(false)
  const { search } = useLocation()
  const redirectedFrom = props.location.state?.from || '/meetings'
  const { t } = useTranslation()

  const handleSwedishNumberInput = (event: any) => {
    setOtherDeviceButtonDisabled(!validateSwedishPersonalNumber(event.target.value))
    setPersonalNumber(event.target.value)
  }

  const createCriiptoUrl = (
    acr_values: 'urn:grn:authn:se:bankid:same-device' | 'urn:grn:authn:se:bankid:another-device',
    login_hint?: string
  ) => {
    return `${
      process.env.REACT_APP_CRIIPTO_DOMAIN as string
    }/oauth2/authorize?${new URLSearchParams({
      client_id: process.env.REACT_APP_CRIIPTO_CLIENT_ID as string,
      scope: process.env.REACT_APP_CRIIPTO_SCOPE as string,
      redirect_uri: process.env.REACT_APP_CRIIPTO_REDIRECT_URI as string,
      response_type: 'code',
      acr_values,
      login_hint: login_hint || '',
    })}`
  }

  const handleLoginButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const url = createCriiptoUrl('urn:grn:authn:se:bankid:another-device', `sub:${personalNumber}`)
    window.location.replace(url)
  }

  const handleLoginButtonSameDevice = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const url = createCriiptoUrl('urn:grn:authn:se:bankid:same-device')
    window.location.replace(url)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const code = searchParams.get('code')
    setAuthLoading(true)
    if (code) {
      auth.authenticateWithCode(code).then((result) => {
        if (result === true) {
          history.replace(redirectedFrom)
        }
        setAuthLoading(false)
      })
    } else {
      if (auth.loginBySavedTokens()) {
        history.replace(redirectedFrom)
      }
      setAuthLoading(false)
    }
  }, [search])

  return (
    <BlankLayout>
      <Box className='content-center'>
        {authLoading ? (
          <Typography variant='body1'>{t('login.loading')}</Typography>
        ) : (
          <Card sx={{ zIndex: 1, borderRadius: 4 }}>
            <CardContent sx={{ padding: (theme) => `${theme.spacing(12, 12, 7)} !important` }}>
              <Box sx={{ mb: 6 }}>
                <Typography variant='h4' sx={{ mb: 4 }}>
                  {t('login.title')}
                </Typography>
                <Typography variant='body1'>{t('login.subtitle')}</Typography>
              </Box>
              <form noValidate autoComplete='off' onSubmit={(e) => e.preventDefault()}>
                <Box sx={{ mt: 10 }}>
                  <Button
                    fullWidth
                    size='large'
                    variant='contained'
                    sx={{ textTransform: 'none', color: 'text.additional' }}
                    onClick={handleLoginButtonSameDevice}
                  >
                    {t('login.login')}
                  </Button>
                </Box>
                <Divider sx={{ mb: 6, mt: 8 }}>
                  <Typography variant='body2' sx={{ ml: '10px', mr: '10px' }}>
                    {t('login.or')}
                  </Typography>
                </Divider>
                <Box>
                  <Typography variant='body1' align='center' sx={{ fontSize: '.9rem' }}>
                    {t('login.login-on-another')}{' '}
                  </Typography>
                </Box>
                <TextField
                  autoFocus
                  fullWidth
                  size='small'
                  sx={{ marginBottom: 4, marginTop: 4 }}
                  onChange={handleSwedishNumberInput}
                  placeholder={t('login.placeholder')}
                  InputProps={{
                    inputComponent: TextMaskCustom as any,
                  }}
                  value={personalNumber}
                />
              </form>
              <Box>
                <Button
                  disabled={otherDeviceButtonDisabled}
                  fullWidth
                  size='large'
                  variant='contained'
                  sx={{ mb: 7, textTransform: 'none', color: 'text.additional' }}
                  onClick={handleLoginButtonClick}
                >
                  {t('login.login-on-another')}
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
    </BlankLayout>
  )
}

export default Login
