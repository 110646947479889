import React from 'react'

const Meetings = React.lazy(() => import('src/pages/meetings/'))
const MeetingId = React.lazy(() => import('src/pages/meetings/[id]'))
const Patients = React.lazy(() => import('src/pages/patients/'))
const PatientId = React.lazy(() => import('src/pages/patients/[id]'))
const Payments = React.lazy(() => import('src/pages/payments'))
const Bookings = React.lazy(() => import('src/pages/bookings'))
const Subscriptions = React.lazy(() => import('src/pages/subscriptions'))
const ExportUsersReview = React.lazy(() => import('src/pages/export-users-review'))

const Login = React.lazy(() => import('src/pages/login'))
const Error404 = React.lazy(() => import('src/pages/404'))

export const routesArr = [
  { path: '/meetings', exact: true, name: 'Meetings', component: Meetings },
  { path: '/meetings/:id', name: 'MeetingId', component: MeetingId },
  { path: '/patients', exact: true, name: 'Patients', component: Patients },
  { path: '/patients/:id', name: 'PatientId', component: PatientId },
  { path: '/payments', exact: true, name: 'Payments', component: Payments },
  { path: '/payments/:id', name: 'MeetingId', component: MeetingId },
  { path: '/bookings', exact: true, name: 'Booking', component: Bookings },
  { path: '/bookings/:id', name: 'BookingId', component: MeetingId },
  { path: '/subscriptions', name: 'Subscriptions', component: Subscriptions },
  { path: '/export-users-review', name: 'ExportUsersReview', component: ExportUsersReview },
]

export const routesBlank = [
  { path: '/login', exact: true, name: 'Login', component: Login },
  { path: '*', name: 'Error404', component: Error404 },
]

export type RouteType = {
  path: string
  component?: any
  exact?: boolean
  name?: string
}
