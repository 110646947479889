// ** React Import
import { ReactNode } from 'react'

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Configs
import themeConfig from 'src/lib/configs/themeConfig'

import { Link } from 'react-router-dom'
import AvatarIcon from 'src/styles/icons/AvatarIcon.svg'
import { useAuth } from 'src/@core/hooks/useAuth'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  verticalNavMenuBranding?: (props?: any) => ReactNode
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: '#abddce',
  paddingRight: theme.spacing(4.5),
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight,
}))

const HeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  transition: 'opacity .25s ease-in-out, margin .25s ease-in-out',
}))

const CaregiverName = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  opacity: '.6',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
}))

const styledLink = {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
}

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const { verticalNavMenuBranding: userVerticalNavMenuBranding } = props
  const auth = useAuth()

  return (
    <MenuHeaderWrapper className='nav-header' sx={{ pl: 6 }}>
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <>
          <Link to='/' style={styledLink}>
            <HeaderTitle variant='h5' sx={{ ml: 10, pt: 6 }}>
              {themeConfig.templateName}
            </HeaderTitle>
          </Link>
          <Box sx={{ pl: 10 }}>
            <CaregiverName>
              <img src={AvatarIcon} style={{ opacity: '.8', marginRight: 5 }} />
              {auth.authed ? auth.user : '...'}
            </CaregiverName>
          </Box>
        </>
      )}
    </MenuHeaderWrapper>
  )
}

export default VerticalNavHeader
